import { configureStoreWithRootReducer } from '@consumer-webapp/shell-redux-store-contract'

import { rootReducer } from './rootReducer'

export const configureStore = configureStoreWithRootReducer(
  rootReducer,
  (store) => {
    module.hot?.accept('./rootReducer', () =>
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      store.replaceReducer(require('./rootReducer').rootReducer)
    )
  }
)
