import { combineReducers } from '@reduxjs/toolkit'

import {
  secondAdvertisingLayerVisibilityContract,
  stickyBillboardContract,
} from '@consumer-webapp/advertising-feature'
import * as cookiesContract from '@consumer-webapp/cookies-contract'
import { fetchSavedSearchesContract } from '@consumer-webapp/fetch-saved-searches-contract'
import { reducer as filtersConfigReducer } from '@consumer-webapp/filters-config-contract'
import * as filtersContract from '@consumer-webapp/filters-data-contract'
import { followedDealersContract } from '@consumer-webapp/followed-dealers-contract'
import { homeContract } from '@consumer-webapp/home-data-contract'
import { insuranceEstimationContract } from '@consumer-webapp/insurance-estimation-feature'
import { parkingsContract } from '@consumer-webapp/parkings-feature'
import * as referenceDataContract from '@consumer-webapp/reference-data-contract'
import { savedSearchesContract } from '@consumer-webapp/saved-searches-data-contract'
import { dspContract, srpContract } from '@consumer-webapp/search-data-contract'
import * as themeContract from '@consumer-webapp/theme-mode-contract'
import * as userContract from '@consumer-webapp/user-data-contract'
import { vipContract } from '@consumer-webapp/vip-data-contract'

export const rootReducer = combineReducers({
  advertising: combineReducers({
    stickyBillboardStatus: stickyBillboardContract.reducer,
    isSecondAdvertisingLayerVisible:
      secondAdvertisingLayerVisibilityContract.reducer,
  }),
  insurance: combineReducers({
    estimatedRates: insuranceEstimationContract.reducer,
  }),
  parkings: combineReducers({
    parkedAds: parkingsContract.reducer,
  }),
  home: homeContract.reducer,
  shared: combineReducers({
    cookies: cookiesContract.reducer,
    user: userContract.reducer,
    filters: filtersContract.reducer,
    filtersConfig: filtersConfigReducer,
    referenceData: referenceDataContract.reducer,
    savedSearches: fetchSavedSearchesContract.reducer,
    theme: themeContract.reducer,
  }),
  search: combineReducers({
    srp: srpContract.reducer,
    'srp.similarTopAds': srpContract.similarTopAds.reducer,
    'srp.srxAds': srpContract.srxAds.reducer,
    dsp: dspContract.reducer,
    vip: vipContract.reducer,
  }),
  ssp: combineReducers({
    savedSearches: savedSearchesContract.reducer,
    followedDealers: followedDealersContract.reducer,
  }),
})
