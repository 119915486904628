import type { ChangeEvent, FC } from 'react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'

import LoadingIndicator from '@mde-ui/loading-indicator'

import { getConfig } from '@consumer-webapp/config-utils'
import { NotFoundPage } from '@consumer-webapp/error-pages-component'
import { fetchSavedSearchesContract } from '@consumer-webapp/fetch-saved-searches-contract'
import Footer from '@consumer-webapp/footer-component'
import Header from '@consumer-webapp/header-feature'
import { homeRoutes } from '@consumer-webapp/home-data-contract'
import { desktopInfoAppRoutes } from '@consumer-webapp/info-data-contract'
import { parkingsContract } from '@consumer-webapp/parkings-feature'
import { savedSearchesRoutes } from '@consumer-webapp/saved-searches-data-contract'
import { searchAppRoutes } from '@consumer-webapp/search-desktop-navigation-contract'
import {
  SavedSearchesCountContext,
  SavedSearchesCountContextProvider,
} from '@consumer-webapp/search-desktop-saved-searches-contract'
import * as themeContract from '@consumer-webapp/theme-mode-contract'
import { TrackingPageNames } from '@consumer-webapp/tracking-contract'
import { fetchUserData } from '@consumer-webapp/user-data-contract'
import { vipDesktopAppRoutes } from '@consumer-webapp/vip-desktop-navigation-contract'

import { MetaTags } from './components/MetaTags'
import type { User } from './types'

import './App.global.css'
import styles from './App.css'

const Home = loadable(
  () =>
    import(
      /* webpackChunkName: 'desktop.home' */ '@consumer-webapp/home-desktop-app'
    ),
  { fallback: <LoadingIndicator /> }
)

const Search = loadable(
  () =>
    import(
      /* webpackChunkName: 'desktop.search' */ '@consumer-webapp/search-desktop-app'
    ),
  { fallback: <LoadingIndicator /> }
)

const SavedSearches = loadable(
  () =>
    import(
      /* webpackChunkName: 'saved-searches' */ '@consumer-webapp/saved-searches-app'
    ),
  { fallback: <LoadingIndicator /> }
)

const Info = loadable(
  () =>
    import(
      /* webpackChunkName: 'info.desktop' */ '@consumer-webapp/info-desktop-app'
    ),
  { fallback: <LoadingIndicator /> }
)

const ViewItemPage = loadable(
  () =>
    import(
      /* webpackChunkName: 'vip.desktop' */ '@consumer-webapp/vip-desktop-app'
    ),
  { fallback: <LoadingIndicator /> }
)

type AppProps = {
  lang: string
  user: User
}

const App: FC<AppProps> = ({ lang = 'de', user }) => {
  const dispatch = useDispatch()
  const { vi, isKnownUser } = user
  const savedSearchesCount = useSelector(
    fetchSavedSearchesContract.selectSavedSearchesCount
  )

  /*
   In desktop, we have two "/" routes:
   - one for the homepage on www.mobile.de
   - one for the SEO landing page on suchen.mobile.de.
   To prevent client side routing straight to the homepage for suchen.mobile.de,
   we need to get the app depending on the hostname, which is checked on serverside in renderer.js.
  */
  const { isHome } = getConfig()

  useEffect(() => {
    dispatch(parkingsContract.getParkedAds())
    dispatch(fetchSavedSearchesContract.fetchSavedSearches({}))
    dispatch(themeContract.setTheme(getConfig()?.theme ?? 'system'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(fetchUserData({ vi, isKnownUser, lang }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, vi, isKnownUser])

  const onThemeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(themeContract.setTheme(event.target.value))
  }

  return (
    <>
      <MetaTags />
      <SavedSearchesCountContextProvider
        initialCount={savedSearchesCount}
        isKnownUser={isKnownUser}
      >
        <Switch>
          <Route path={homeRoutes} exact>
            {isHome ? (
              <>
                <Header
                  savedSearchesCount={savedSearchesCount}
                  trackingPageName={TrackingPageNames.HOME}
                />
                <Home lang={lang} />
                <Footer
                  className={styles.footer}
                  lang={lang}
                  onThemeUpdate={onThemeChange}
                  trackingPageName={TrackingPageNames.HOME}
                  isFullVersion
                />
              </>
            ) : (
              <Search lang={lang} />
            )}
          </Route>
          <Route path={searchAppRoutes} exact>
            <Search lang={lang} />
          </Route>
          <Route path={savedSearchesRoutes} exact>
            <SavedSearchesCountContext.Consumer>
              {({ savedSearchesCount }) => (
                <Header
                  savedSearchesCount={savedSearchesCount}
                  trackingPageName={TrackingPageNames.SSP}
                />
              )}
            </SavedSearchesCountContext.Consumer>
            <SavedSearches lang={lang} />
            <Footer
              className={styles.footer}
              lang={lang}
              trackingPageName={TrackingPageNames.SSP}
            />
          </Route>
          <Route path={vipDesktopAppRoutes}>
            <SavedSearchesCountContext.Consumer>
              {({ savedSearchesCount }) => (
                <ViewItemPage
                  lang={lang}
                  savedSearchesCount={savedSearchesCount}
                />
              )}
            </SavedSearchesCountContext.Consumer>
          </Route>
          <Route path={desktopInfoAppRoutes}>
            <Info />
          </Route>
          <Route path="*">
            <NotFoundPage />
            <Footer
              className={styles.footer}
              lang={lang}
              trackingPageName={TrackingPageNames.NOTFOUND}
            />
          </Route>
        </Switch>
      </SavedSearchesCountContextProvider>
    </>
  )
}

export default App
