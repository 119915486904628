import { homePageData } from '@consumer-webapp/home-data-contract'
import { savedSearchesPageData } from '@consumer-webapp/saved-searches-data-contract'
import { searchPageData } from '@consumer-webapp/search-data-contract'
import { vipDesktopPageData } from '@consumer-webapp/vip-data-contract'

export const pageData = [
  ...homePageData,
  ...searchPageData,
  ...savedSearchesPageData,
  ...vipDesktopPageData,
]
