import type { FC } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import favIcon from './assets/favicon.png'
import touchIcon from './assets/touchicon.png'

export const MetaTags: FC = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta content="telephone=no" name="format-detection" />
    <meta content="notranslate" name="google" />

    <link href={favIcon} rel="shortcut icon" type="image/png" />
    <link href={touchIcon} rel="icon" sizes="192x192" type="image/png" />
    <link
      href={touchIcon}
      rel="apple-touch-icon"
      sizes="192x192"
      type="image/png"
    />
  </Helmet>
)
