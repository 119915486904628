import { initialize } from '@mobile-de/trackking'
import type { ReduxStore } from '@mobile-de/trackking/core/types'
import type { GoogleTagManagerConfig } from '@mobile-de/trackking/services/google-tag-manager'
import type { OptimizelyConfig } from '@mobile-de/trackking/services/optimizely'

import { initGoogleTagManager } from './services/google-tag-manager'
import { initOptimizely } from './services/optimizely'

type Config = {
  GoogleTagManager?: GoogleTagManagerConfig
  Optimizely?: OptimizelyConfig
}

export function initializeTracking(
  { GoogleTagManager, Optimizely }: Config = {},
  store: ReduxStore
): void {
  initialize(
    [
      initOptimizely(Optimizely as OptimizelyConfig),
      initGoogleTagManager(GoogleTagManager),
    ],
    store
  )
}
