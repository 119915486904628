import { PublicHeaderTrackingServices as headerTrackingServices } from '@mde-ui/header'
import GoogleTagManager from '@mobile-de/trackking/services/google-tag-manager'

import { trackingServices as followedDealersTrackingServices } from '@consumer-webapp/followed-dealers-contract'
import { trackingServices as homeTrackingServices } from '@consumer-webapp/home-data-contract'
import { trackingServices as savedSearchTrackingServices } from '@consumer-webapp/saved-searches-data-contract'
import { trackingServices as searchTrackingServices } from '@consumer-webapp/search-desktop-tracking-contract'
import {
  footerTrackingServices,
  trackExperimentBegin,
} from '@consumer-webapp/tracking-contract'
import { trackingServices as vipTrackingServices } from '@consumer-webapp/vip-tracking-contract'

type Config = Record<string, unknown>

const globalEventSubscriptions = {
  APP_INIT: () => [...trackExperimentBegin('gtm')],
}

const eventsMap = {
  ...globalEventSubscriptions,
  ...homeTrackingServices.GoogleTagManager,
  ...headerTrackingServices.GoogleTagManager,
  ...savedSearchTrackingServices.GoogleTagManager,
  ...searchTrackingServices.GoogleTagManager,
  ...followedDealersTrackingServices.GoogleTagManager,
  ...footerTrackingServices.GoogleTagManager,
  ...vipTrackingServices.GoogleTagManager,
}

export const initGoogleTagManager = (config: Config = {}) =>
  new GoogleTagManager(eventsMap, config)
