import 'core-js/stable'

import React from 'react'
import { hydrate } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { loadableReady } from '@loadable/component'

import { dispatchTrackEvent } from '@mobile-de/trackking'

import { setConfig } from '@consumer-webapp/config-utils'
import { retry } from '@consumer-webapp/fetch-utils'
import { loadTranslations } from '@consumer-webapp/i18n-utils'
import { loadPolyfills } from '@consumer-webapp/polyfills-utils'

import App from './App'
import { configureStore, initializeTracking } from './data'
import type { User } from './types'

type RenderProps = {
  messages: Record<string, string>
  lang: string
  user: User
  store: ReturnType<typeof configureStore>
}

const render = ({ messages, lang, user, store }: RenderProps) =>
  loadableReady(() =>
    hydrate(
      <IntlProvider
        defaultLocale="de"
        locale={lang}
        messages={messages}
        textComponent={React.Fragment}
      >
        <HelmetProvider>
          <Provider store={store}>
            <Router>
              <App lang={lang} user={user} />
            </Router>
          </Provider>
        </HelmetProvider>
      </IntlProvider>,
      document.getElementById('root')
    )
  )

// @ts-ignore
const initialState = window.__INITIAL_STATE__ || {}
const store = configureStore(initialState)
// @ts-ignore
const config = window.__PUBLIC_CONFIG__ || {}
const lang = config.lang || 'de'
const user = config?.user

initializeTracking(config.tracking as object | undefined, store)

setConfig(config)

const displayCriticalErrorOverlay = () => {
  const div = document.createElement('div')
  div.style.cssText = `
  text-align: center;
  padding: 9px;
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: white;
  background-color: #F2193A;
  font-size: 14.4px
  `
  if (lang === 'en') {
    div.innerHTML = `<b>Connection error:</b> please <a style="color:white" href='javascript:location.reload()'>reload the page</a>.`
  } else {
    div.innerHTML = `<b>Verbindungsfehler:</b> Bitte <a style="color:white" href='javascript:location.reload()'>lade die Seite neu</a>. `
  }

  document.body.appendChild(div)
}

Promise.all([retry(() => loadTranslations(lang), 5, 1000), loadPolyfills()])
  .then(([messages]) => {
    dispatchTrackEvent('APP_INIT')
    render({
      messages: messages.default,
      lang,
      user,
      store,
    } as RenderProps)
  })
  .catch((error) => {
    displayCriticalErrorOverlay()
    console.error("Couldn't start application", error)
  })
