import type { OptimizelyConfig } from '@mobile-de/trackking/services/optimizely'
import Optimizely from '@mobile-de/trackking/services/optimizely'

import { trackingServices as homeTrackingServices } from '@consumer-webapp/home-data-contract'
import { getDebugLogger } from '@consumer-webapp/optimizely-debug-util'
import { trackingServices as savedSearchTrackingServices } from '@consumer-webapp/saved-searches-data-contract'
import { trackingServices as searchTrackingServices } from '@consumer-webapp/search-desktop-tracking-contract'
import { trackingServices as vipTrackingServices } from '@consumer-webapp/vip-tracking-contract'

const eventsMap = {
  ...homeTrackingServices.Optimizely,
  ...savedSearchTrackingServices.Optimizely,
  ...searchTrackingServices.Optimizely,
  ...vipTrackingServices.Optimizely,
}

export const initOptimizely = (config: OptimizelyConfig) =>
  new Optimizely(eventsMap, {
    ...config,
    logger: getDebugLogger(2),
  })
